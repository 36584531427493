.payment-list {
  padding: 16px 16px;
}

.payment-item {
  font-size: 16px;
}

.payment-item + .payment-item {
  margin-top: 8px;
}
