.midBoard-container {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
}

.midBoard-content {
  width: 49%;
}

.midBoard-title {
  font-weight: 700;
}

.midBoard-chart-container {
  margin-top: 16px;
  padding: 32px;
  border-radius: 10px;
  background-color: #e9ecef;
}

.midBoard-full-content {
  width: 100%;
}
