.dashBoard-wrapper {
  display: flex;
}

.dashBoard-tab__list {
  margin-top: 24px;
  display: flex;
  gap: 32px;
}

.dashBoard-tab__item {
  font-weight: 700;
  font-size: 28px;
  color: lightgray;
  cursor: pointer;
}

.dashBoard-tab__item--active {
  color: black;
}

.dashBoard-content-wrapper {
  width: 86%;
}

.dashBoard-tab-content__container {
  width: 100%;
  margin-top: 16px;
  padding-bottom: 3rem;
}
