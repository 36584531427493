a {
  text-decoration: none;
  color: #000;
}

.invalid-feedback {
  display: block;
}

.no-scroll {
  -ms-overflow-style: none;
  /* IE, Edge */
  scrollbar-width: none;
  /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

ul {
  padding-left: 0px;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

label {
  /* white-space:nowrap; */
  margin-bottom: 0.1rem;
}

.form-label {
  /* white-space:nowrap; */
  margin-bottom: 0.1rem;
}

input {
  font-family: "FontAwesome";
}

@font-face {
  font-family: "Noto Sans KR";
  transform: rotate(0.04deg);
  /* Anti-aliasing css */
  src: url("./fonts/NotoSansKR-Regular.otf"), url("./fonts/NotoSansKR-Bold.otf"),
    url("./fonts/NotoSansKR-Light.otf"), url("./fonts/NotoSansKR-Medium.otf"),
    url("./fonts/NotoSansKR-Black.otf"), url("./fonts/NotoSansKR-Thin.otf");
}

* {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
}

ul {
  list-style: none;
  margin-bottom: 0px;
}

.UlStyle {
  margin-bottom: 0px;
  padding-left: 0.5rem;
  list-style-type: disc;
}

.UlStyle li {
  list-style: disc outside none;
  display: list-item;
  margin-left: 1em;
}

h4 {
  font-weight: bold;
}

.form-label {
  color: #7f8080;
}

.form-control::placeholder {
  color: #b2b2b2;
  font-size: 8pt;
}

.form-check-label {
  color: #b2b2b2;
  font-size: 8pt;
  border-radius: 50%;
}

.form-control {
  border: none;
  border-bottom: 0.5px solid #b2b2b2;
  border-radius: 0;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"] {
  border-radius: 50%;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
}

.MainSpace {
  margin-top: 60px;
}

.form-select {
  border-color: #cb5361;
}

.form-check-input:checked {
  background-color: #cb5361;
  border-color: #cb5361;
}

.accordion-button:not(.collapsed) {
  background-color: #eaa9b1;
  color: #ffffff;
}

.UserNavgate {
  background-color: #cb5361;
  color: #ffffff;
}

.SearchNavgate {
  background-color: #ffffff;
  color: #ffffff;
}

.SearchNavgate input {
  border: none;
}

/* .navbar-brand{
  color:#ffffff;
} */
.PointText {
  color: #cb5361 !important;
}

.btn-default {
  color: #000000;
  --bs-btn-color: #fff;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #cb5361;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cb5361;
  --bs-btn-hover-border-color: #cb5361;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cb5361;
  --bs-btn-active-border-color: #cb5361;
  /* --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: lightgray;
  --bs-btn-disabled-border-color: lightgray;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #cb5361;
  --bs-btn-border-color: #cb5361;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cb5361;
  --bs-btn-hover-border-color: #cb5361;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cb5361;
  --bs-btn-active-border-color: #cb5361;
  /* --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: lightgray;
  --bs-btn-disabled-border-color: lightgray;
}

.form-control:focus {
  /* outline: none !important;
  border:1px solid red;
  box-shadow: 0 0 10px #719ECE; */

  border-bottom: 0.5px solid #cb5361;
}

.ColorArea {
  background-color: #f1f1f1;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 0%);
}

.TableTitle {
  font-weight: bold;
  color: #000;
}

.FooterArea {
  background-color: #f1f1f1;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.HeaderArea {
  background-color: #f1f1f1;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.Footer {
  color: #7f8080;
}

.Box {
  border: 0.5px solid #b2b2b2;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.InputBox {
  background-color: #b2b2b2;
}

.Footer > tbody > tr > td {
  border-bottom-width: 0px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 8pt;
}

/* .row > .col {
  padding-top: 15px;
  padding-bottom : 15px;
} */

.TextRight {
  text-align: right;
}

.postCodeStyle {
  display: block;
  position: relative;
  top: 0%;
  width: 100%;
  height: 400px;
  padding: 7px;
}

.ContentCenter {
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f6f6f6;
  --bs-btn-border-color: #f6f6f6;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f6f6f6;
  --bs-btn-hover-border-color: #f6f6f6;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f6f6f6;
  --bs-btn-active-border-color: #f6f6f6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f6f6f6;
  --bs-btn-disabled-border-color: #f6f6f6;
  color: #000000;
  /* border:0.5px solid #b4b3b3;  */
}

.btn-secondary:hover {
  background-color: #cb5361;
  border-color: #cb5361;
  color: #ffffff;
}

.InputGroupTextCtm {
  width: 50%;
  border-radius: 0;
  border: 0px;
}

.TextTitle {
  color: #000000;
  font-weight: bold;
}

.LabelTitle {
  color: #000000;
  font-weight: bold;
}

.LineBreak {
  width: 100%;
  height: 10px;
  background-color: #f2f2f2;
}

dd {
  font-size: 8pt;
  color: #989898;
}

.shadow-lg {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.075) !important;
}

.tab-content {
  padding-top: 20px;
  padding-right: 0px;
  padding-left: 0px;
}

.TextTable tbody tr {
  border-style: none;
}

.TextTable tbody tr td {
  border-bottom-width: 0px;
  min-width: 90px;
}

.progress {
  height: 6px;
}

.progress-bar {
  background: linear-gradient(to right, #f6921e, #f9d600 100%);
}

.CustomRadio {
  font-size: 16px !important;
  font-weight: bold;
}

.CustomRadio > .form-check-label {
  font-size: 16px !important;
  font-weight: bold;
  color: #000;
}

.BigPlaceholder {
  color: #000000 !important;
  font-size: 16pt;
}

.Gradient {
  color: rgb(255, 196, 0);
  color: linear-gradient(to right, #f6921e, #ffdd00, #f9d600 100%);
}

.UnGradient {
  color: #454b4b;
  color: linear-gradient(to right, #454b4b, #ffffff, #ffffff 100%);
}

.ReviewScore {
  font-size: 40pt;
  text-align: center;
  vertical-align: middle;
}

.AlignCenter {
  text-align: center !important;
  vertical-align: middle !important;
  align-items: center !important;
}

.AlignRight {
  text-align: right !important;
  vertical-align: middle !important;
  align-items: center !important;
}

.AlignCenterCB {
  display: flex;
  align-items: center;
}

pre {
  white-space: pre-wrap;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
}

.Logo {
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.ReviewImg {
  margin-top: 5px;
}

.list-group-horizontal {
  --bs-list-group-border-color: rgba(0, 0, 0, 0);
  --bs-list-group-border-width: 1px;
  padding-right: 0px;
}

.list-group-item {
  padding-right: 0px;
}

.DefaultCheckBox .form-check {
  padding-left: 1.7em;
  margin-bottom: 0;
}

.DefaultCheckBox input {
  font-size: 12pt;
  color: #000000;
  margin-left: -1em !important;
}

.DefaultCheckBox label {
  font-size: 12pt;
  color: #000000;
}

.Ranking ul {
  padding-left: 0px;
}

.FlexMainColor {
  color: #cb5361;
}

.FlexSubColor {
  color: #b2b2b2;
}

.WBox {
  background-color: #ffffff;
}

.BlackLabel {
  color: #000000;
}

.DefaultShadow {
  box-shadow: 0 0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.03) !important;
}

.DefaultRaund {
  border-radius: 10px;
}

.input-group-text {
  background-color: #ffffff;
  border: none;
}

.Label404 {
  color: #454b4b;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  /*--bs-nav-link-font-weight: ;*/
  --bs-nav-link-color: #000000;
  --bs-nav-link-hover-color: #000000;
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.badge {
  color: #454b4b;
}

.badgesel {
  color: #cb5361;
  font-size: 12px;
}

.ItemImgSize {
  max-width: 100px;
  max-height: 100px;
}

.UnderBarUl li {
  font-size: 16px;
  /* border-bottom : 1px solid #B2B2B2; */
}

.w-15 {
  width: 15%;
  height: 15%;
}

.carousel-item > img {
  max-height: 260px;
}

.carousel-item .carousel-caption {
  left: 10%;
  z-index: 999;
}

.DefailtItemList {
  width: 80px;
  height: 80px;
  background-color: #cb5361;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18pt;
  font-weight: bolder;
}

.LogoImg {
  width: 100px;
  margin-top: 20%;
  margin-bottom: 20%;
}

.form-label {
  margin-bottom: 0.8rem;
}

/* UI 보완 */

@font-face {
  font-family: "Pretendard";
  transform: rotate(0.04deg);
  /* Anti-aliasing css */
  src: url("./fonts/Pretendard-Thin.woff")
      url("./fonts/Pretendard-ExtraLight.woff"),
    url("./fonts/Pretendard-Light.woff"), url("./fonts/Pretendard-Regular.woff"),
    url("./fonts/Pretendard-Medium.woff"),
    url("./fonts/Pretendard-SemiBold.woff"), url("./fonts/Pretendard-Bold.woff"),
    url("./fonts/Pretendard-ExtraBold.woff"),
    url("./fonts/Pretendard-Black.woff");
}

* {
  font-family: "Pretendard", sans-serif;
  font-size: 14px;
}

.LeftBg {
  max-width: 245px;
  min-width: 245px;
}

.LeftMenu {
  background: linear-gradient(to right, #ff195a, #ff5864, #ff655b);
  min-height: 88vh;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.LeftMenu::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  z-index: -1;
}

.LeftMenu .accordion-item {
  background-color: transparent;
  border-bottom: 0;
}

.LeftMenu .accordion-button {
  font-size: 1.1rem;
  background-color: transparent;
  color: #fff;
  font-weight: 600;
  opacity: 0.8;
}

.LeftMenu .accordion-button::after {
  background-image: url(https://www.flexdaycdn.net/public/images/static/ui/arrow_white.png);
  background-position-y: center;
}

.LeftMenu .accordion-button:not(.collapsed)::after {
  background-image: url(https://www.flexdaycdn.net/public/images/static/ui/arrow_white.png);
}

.LeftMenu .accordion-button:not(.collapsed) {
  box-shadow: none;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
}

.LeftMenu .accordion-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.LeftMenu .accordion-button img {
  width: 30px;
  margin-right: 15px;
}

.LeftMenu .accordion-body {
  background-color: rgba(255, 255, 255, 0.85);
}

.LeftMenu .accordion-body > ul {
  padding-left: 45px;
}

.LeftMenu .accordion-body > ul > li {
  margin-bottom: 0.75rem;
}

.LeftMenu .accordion-body > ul > li:last-child {
  margin-bottom: 0;
}

.LeftMenu .accordion-body > ul > li > a {
  color: #333333;
  display: block;
  font-size: 1.1rem;
}

.RightBg {
  background: #f7f7f7;
}

.ContentBox {
  background: #fff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 3px 3px 15px 2px rgb(0 0 0 / 10%);
}

.ContentBox:last-child {
  margin-bottom: 5rem;
}

.TableStyle thead {
  vertical-align: middle;
}

.TableStyle thead tr th {
  padding: 0.75rem 0.5rem;
  background-color: #333333;
  color: #fff;
  font-weight: 400;
  word-break: keep-all;
}

.TableStyle .btn-outline-secondary {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff5864;
  --bs-btn-hover-border-color: #ff5864;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff5864;
  --bs-btn-active-border-color: #ff5864;
  --bs-btn-padding-y: 0.25rem;
}

.TableStyle tbody {
  background-color: #fff;
}

.pagination {
  --bs-pagination-font-size: 1.1rem;
  --bs-pagination-color: #333333;
  --bs-pagination-border-color: transparent;
  --bs-pagination-hover-color: #ff5864;
  --bs-pagination-hover-bg: transparent;
  --bs-pagination-hover-border-color: transparent;
  --bs-pagination-focus-color: #333333;
  --bs-pagination-focus-bg: transparent;
  --bs-pagination-focus-box-shadow: none;
  justify-content: center;
}

.pagination .page.on .page-link {
  font-weight: bold;
  color: #ff5864;
}

.pagination .page-link {
  background-color: transparent;
}

.PaginationFirst .page-link span,
.PaginationLast .page-link span,
.PaginationPrev .page-link span,
.PaginationNext .page-link span {
  font-size: 2rem;
  line-height: 1;
}

.btn-primary {
  --bs-btn-bg: #ff5864;
  --bs-btn-border-color: #ff5864;
  --bs-btn-hover-bg: #ff5864;
  --bs-btn-hover-border-color: #ff5864;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff5864;
  --bs-btn-active-border-color: #ff5864;
}

.btn-secondary:hover {
  background-color: #333333;
  border-color: #333333;
}

.form-select {
  border-color: #ddd;
}

.btn-black .btn-primary {
  --bs-btn-bg: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-bg: #333333;
  --bs-btn-hover-border-color: #333333;
  --bs-btn-active-bg: #333333;
  --bs-btn-active-border-color: #333333;
}

.Formbox .form-label {
  font-family: "Pretendard", sans-serif;
  color: #333333;
  font-size: 1.1rem;
  font-weight: 700;
}

.Formbox .form-label .PointText {
  color: #ff5864 !important;
}

.Formbox .form-control {
  border-bottom: 0px;
  border-radius: 8px;
  background-color: #fff0f0;
  padding: 0.75rem 1rem;
  color: #333333;
  font-family: "Pretendard", sans-serif;
  border: 1px solid var(--bs-gray-400);
}

.Formbox .form-control:disabled {
  border: 1px solid var(--bs-gray-300);
}

.Formbox .form-control::placeholder {
  font-size: 1rem;
}

.Formbox .form-control:disabled,
.Formbox .form-control[readonly] {
  color: #666666;
  background-color: #f7f7f7;
}

.Formbox .btn-py .btn {
  padding: 0.75rem;
}

a:hover {
  color: #ff5864;
  text-decoration: underline;
}

a.btn:hover {
  text-decoration: none;
}

.loginBox .form-control.inputType {
  font-family: "Pretendard", sans-serif;
  width: 100%;
  height: 40px;
  padding: 0.75rem;
  border-radius: 3px;
  border: 1px solid #c3c3c3;
  font-size: 13px;
  color: #333333;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  transition: none;
}

.loginBox .form-control.inputType::placeholder {
  color: #c3c3c3;
  font-size: 13px;
}

.loginBox .form-control.inputType:focus {
  border-color: #000000;
}

.loginBox .loginPrimary .btn-primary {
  height: 50px;
  border-radius: 3px;
  font-size: 14px;
}

.loginBox .form-check-input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 0;
  border: 1px solid #ddd;
  background: url(https://www.flexdaycdn.net/public/images/static/login/check.png)
    no-repeat center / 12px auto;
  background-color: #fff;
  border-radius: 0.25em;
}

.loginBox .form-check-input:checked[type="checkbox"] {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/check_color.png);
}

.loginBox .form-check-input:active {
  filter: none;
}

.loginBox .form-check-label {
  font-size: 13px;
  color: #333333;
}

.form-radio .form-check-inline {
  margin-bottom: 0;
  margin-right: 2rem;
}

.form-radio .form-check-input {
  width: 24px;
  height: 24px;
  position: relative;
  background-color: #fff;
  border: 2px solid #ddd;
  margin-right: 7px;
  margin-top: 0;
  cursor: pointer;
}

.form-radio .form-check-input:active {
  filter: none;
}

.form-radio .form-check-input:checked[type="radio"] {
  background-image: none;
  border-color: #ff5864;
}

.form-radio .form-check-input::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #fff;
}

.form-radio .form-check-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 50%;
  top: 45%;
  width: 5px;
  height: 10px;
  border: solid #ff5864;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.form-radio .form-check-input:checked::after {
  display: block;
}

.form-radio .form-check-label {
  color: #666666;
  font-size: 15px;
  cursor: pointer;
}

.form-radio .form-check-input:checked + .form-check-label {
  color: #ff5864;
}

.filter-btnbox button {
  font-size: 15px;
  padding: 0.5rem 4rem;
}

/* 23-01-26  */
.board-row {
  cursor: pointer;
}

.board-row:hover {
  background-color: #fff0f0;
}

.board-row-except:hover {
  background-color: #fff0f0;
}

.board-link:hover {
  cursor: pointer;
  color: #008f9d;
  text-decoration-line: underline;
}

.LeftMenu .accordion-item.accordionIconHidden .accordion-button::after {
  display: none;
}

.ql-tooltip {
  margin-top: 0px !important;
  top: 0 !important;
}

/* 커스텀 스위치 스타일 */
.custom-switch.form-check {
  display: flex;
  padding-left: 0;
  width: 50%;
}
.custom-switch .form-check-input {
  width: 3.6rem;
  height: 25px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  padding-left: 0;
  margin-left: 0;
  float: unset;
}

.custom-switch .form-check-input:checked {
  background-color: #ff5864;
}

.custom-switch .form-check-input:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.custom-switch .form-check-input:checked:before {
  transform: translateX(1.8rem);
}

/* 레이블 스타일 */
.custom-switch .form-check-label {
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

#map-container {
  width: 100%;
  height: 29rem;
  background: #333;
  position: relative;
  /* ${(p) =>
    p.$isEmpty &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}; */
  margin-bottom: 2rem;
}
