.bottomBoard-container {
  margin-top: 48px;
}

.bottomBoard-content {
  width: 100%;
}

.bottomBoard-title {
  font-weight: 700;
  padding-right: 100px;
}

.bottomBoard-legend {
  position: relative;
  /* text-align: right; */
  font-size: 16px;
}

.bottomBoard-legend-list {
  position: absolute;
  top: -12px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.bottomBoard-legend-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.bottomBoard-legend--woman {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 10px;
}

.bottomBoard-legend--man {
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 10px;
}

.bottomBoard-chart-container {
  margin-top: 16px;
  padding: 32px;
  border-radius: 10px;
  background-color: #e9ecef;
}
